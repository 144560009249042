/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
// If you have styles imported in your pack file, you can link them by using stylesheet_pack_tag:
// <%= stylesheet_pack_tag 'application' %>

/* Important files for webpacker: app/views/layouts/application.html.haml, config/webpacker.yml,
                                  config/webpack/environment.js

 Refs: https://mariochavez.io/desarrollo/2020/05/19/from-the-asset-pipeline-to-webpack.html
       https://github.com/rails/webpacker
       https://blog.carbonfive.com/migrating-from-sprockets-to-webpacker/
       https://www.freecodecamp.org/news/how-to-add-javascript-to-your-rails-6-app/

 NOTES:                                           If using splitChunks: environment.splitChunks()
 javascript_include_tag -> javascript_pack_tag -> javascript_packs_with_chunks_tag
 stylesheet_link_tag    -> stylesheet_pack_tag -> stylesheet_packs_with_chunks_tag
 favicon_link_tag       -> favicon_pack_tag
 asset_url              -> asset_pack_tag
 image_tag or image_url -> image_pack_tag
 But if you need the image URL, then use asset_pack_url (for relative), or asset_pack_path (for
                   absolute):    <%= asset_pack_url("image1.png") %>
   - There's also image_pack_url and image_pack_path which do the same thing I guess?
 If you need to reference an image from a stylesheet file, then use the url function with the
  relative path to the image:    background-image: url("../images/image1.png");

 When running locally: Don't forget to start postgres and the webpack-dev-server.
 To start the webpack-dev-server:    ./bin/webpack-dev-server
 ALTERNATIVE to webpack-dev-server:  ./bin/webpack --watch --progress
 */

// console.log('Hello World from Webpacker')
 
/*................................................................................................*/
import $ from 'jquery';   
global.$      = $;   
global.jQuery = $;  // How I did it in myvert
// // import $ from "expose-loader?exposes=$,jQuery!jquery"      // From github.com/rails/webpacker

// // github.com/rails/webpacker is a good reference in general, and is where these 2 lines came from:
// import 'core-js/stable'
// import 'regenerator-runtime/runtime'
// /*................................................................................................*/
// // require("@rails/ujs").start();
// // require("turbolinks").start(); /** MUST call .start() here, despite RubyMine warning to contrary. */
// // require("@rails/activestorage").start();
// // // require("channels");

// /** Alternate version, from: https://edgeguides.rubyonrails.org/webpacker.html
//  "You'll need to include a pack that requires these packages to use them in your Rails app." */
// import Rails from "@rails/ujs"
// // import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"
// Rails.start()
// // Turbolinks.start()
// ActiveStorage.start()
// /*................................................................................................*/
// // import('scrollspy2');
// // import('../src/scrollspy2');
// // import('popper.js');    // NOTE: Calling import as a function for dynamic (lazy) loading
// import('@popperjs/core');  // NOTE: Calling import as a function for dynamic (lazy) loading
// // global._ = require('underscore');
// import("bootstrap");
/*................................................................................................*/
/* Uncomment to copy all static images under ../images to the output folder and reference
 them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
 or the `imagePath` JavaScript helper below. */
/* const images = require.context('../images', true) // Commented since not in use. 2021-12-9, JM */
/* const imagePath = (name) => images(name, true) // Commented since not in use. 2021-12-9, JM */

/* Refers to app/javascript/stylesheets/application.scss. Note that we don't need to preface this
 path with "app/javascript" due to the `source_path` config set in config/webpacker.yml. Magical!
 The file extension can be left off due to the `extensions` config in config/webpacker.yml. */
import "stylesheets/application" /// <--- CAUTION: Will fail if called with parens for some reason.
 

/** // import 'bootstrap/dist/css/bootstrap.min.css';
 Ref: https://www.bootrails.com/blog/rails-bootstrap-tutorial/ */
// import 'bootstrap/js/src/alert'  
// import 'bootstrap/js/src/button'  
// import 'bootstrap/js/src/carousel'  
import 'bootstrap/js/src/collapse';  
import 'bootstrap/js/src/dropdown';  
// import 'bootstrap/js/src/modal'  
// import 'bootstrap/js/src/popover'  
import 'bootstrap/js/src/scrollspy';  
// import 'bootstrap/js/src/tab'  
// import 'bootstrap/js/src/toast'  
// import 'bootstrap/js/src/tooltip'  

/*................................................................................................*/
// require.context('../fonts', true); /** 2nd arg == true makes it recursive. */

/*................................................................................................*/
import LocalTime from "local-time"
LocalTime.start()

/*------------------------------------------------------------------------------------------------*/
$(function() { //$(document).ready( () => {
  setTimeout( () => $('.alert-timed').remove(), 5000);
  // setTimeout( () => $('#flash').remove(), 3000);
}); 

// let delayMs = (ms, func) => setTimeout(func, ms);
// // $(function() {
// //   $('.alert-timed').effect('highlight', {}, 500);
// //   delayMs(3000, () => {
// //     return $('.alert-timed').alert('close');
// //   });
// // });
//
// $(function() {
//   delayMs(5000, () => {
//     $('.alert-timed').alert('close');
//     $('.validation-message').hide();
//   });
// });

/*................................................................................................*/



///** Flush cache if browser back button was used. This ensures that user will always see an 
// accurate, up-to-date view based on their state. stackoverflow.com/questions/
//                   8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked */
//(function () {
//	window.onpageshow = function(event) {
//		if (event.persisted) { window.location.reload(); }
//	};
//})();

 


/*---- Moved to app/views/messages/index.haml ----------------------------------------------------*/
// window.initCampaignPicker = function() {
//   console.debug("initCampaignPicker")
//   if ($('form#new_message').length === 0) { return; }
//   const sel = $('form#new_message #message_campaign_id')
//   // sel.change(campaignIDChanged);
//   sel.on('change', campaignIDChanged);  // <-- Try this when I get a sec.
//   return campaignSelected(sel.val());
// };

// window.campaignIDChanged = function(e) {
//   console.debug("campaignIDChanged")
//   let cID = $(e.target).val();
//   return campaignSelected(cID);
// };

// window.campaignSelected = function(cID) {
//   console.debug("campaignSelected") 
//   let camp = window.data_boot.campaigns.find(c => c.id.toString() === cID);
//   return $('#message_sms_body').val(camp.sms_body);
// };

// // document.addEventListener('turbolinks:render', initCampaignPicker);

// // $(document).ready(initCampaignPicker);
// /** In case jQuery's `ready` callback not triggered properly. 
//  Ref: www.fastruby.io/blog/rails/webpack/from-sprockets-to-webpacker.html */
// document.addEventListener('DOMContentLoaded', initCampaignPicker); 

/** Original coffeescript: */

// #= require rails-ujs
// #= require turbolinks
// #= require jquery3
// #= require scrollspy2
// #= require popper
// #= require underscore
// #= require bootstrap
// #= require_tree .
//
// window.init_campaign_picker = ->
//   return if $('form#new_message').length == 0
//   $('form#new_message #message_campaign_id').change campaign_id_changed
//   campaign_selected $('form#new_message #message_campaign_id').val()
//
// window.campaign_id_changed = (e)->
//   c_id = $(e.target).val()
//   campaign_selected(c_id)
//
// window.campaign_selected = (c_id)->
//   camp = _.find(data_boot.campaigns, (c)-> c.id.toString() == c_id)
//   $('#message_sms_body').val(camp.sms_body)
//
// document.addEventListener('turbolinks:render', init_campaign_picker)
// $(document).ready init_campaign_picker
